import 'bootstrap';
import { initLocale } from '../../i18n';

export function createPopoverComponent({containers}) {
  // ============================================
  // =============== UI ==================
  const popoverSelectorOption = (index, item) => {
    let id = `check${index}`;
    return `
      <div class="container-popover-select-row">
        <span class="container-popover-select-span custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="${id}" ${item.checked ? 'checked' : ''}>
          <label class="custom-control-label" for="${id}">${item.text}</label>
        </span>

        <span class="container-popover-select-span-action ${item.checked ? '' : 'invisible'}">
          <button class="container-popover-minus" ${item.count <= 1 ? `disabled` : ''}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 11H11H7V13H11H13H17V11H13ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#9E9E9E"/>
            </svg>
          </button>
          <span class="count">${item.count}</span>
          <button class="container-popover-plus">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#9E9E9E"/>
            </svg>
          </button>
        </span>
      </div>
    `;
  };

  const mapJoin = (items, cb) => {
    return items.map(cb).join('');
  };

  const popoverUi = state => {
    let listItems = mapJoin(state, (item, index) => {
      return popoverSelectorOption(index, item);
    });

    let doneButton = `
        <div class="popover-done-button-wrapper">
          <button class="btn btn-link">
            ${translation['done']}
          </button>
        </div>
    `;

    return `
      ${listItems}
      ${doneButton}
    `;
  };
  // ============================================

  // ============================================ // ============================================

  // ============================================
  // ============== CREATION ====================

  function createPopoverContainer({ selector, containers }) {
    const containerSelectPopover = $(`
      <div class="container-popover-body">
      </div>
    `);

    let popoverState = containers.map(container => {
      return {
        text: container,
        count: 1,
        checked: false,
      };
    });

    const data = gon.search_params && gon.search_params['containers'];
    if (data) {
      let dataObj = {};
      for (let elem of JSON.parse(data)) {
        let splitted = elem.split(':');
        dataObj = {
          [splitted[0]]: splitted[1],
          ...dataObj,
        };
      }

      for (let state of popoverState) {
        if (dataObj[state.text]) {
          state.count = parseInt(dataObj[state.text]);
          state.checked = true;
        }
      }
    }

    function popoverRender() {
      let ui = popoverUi(popoverState);
      containerSelectPopover.html(ui);

      // Checkbox event
      $.each(
        containerSelectPopover.find('.custom-control-input'),
        function (index) {
          let input = $(this);
          input.on('change', function () {
            popoverState[index].checked = !popoverState[index].checked;
            popoverRender();
          });
        },
      );

      // Plus button events
      $.each(
        containerSelectPopover.find('.container-popover-plus'),
        function (index) {
          let button = $(this);
          button.on('click', () => {
            popoverState[index].count++;
            popoverRender();
          });
        },
      );
      // Minus button events
      $.each(
        containerSelectPopover.find('.container-popover-minus'),
        function (index) {
          let button = $(this);
          button.on('click', () => {
            popoverState[index].count--;
            popoverRender();
          });
        },
      );

      // onClick Done
      containerSelectPopover
        .find('.popover-done-button-wrapper button')
        .on('click', popoverClickAction);
    }

    function popoverClickAction() {
      let activeState = popoverState.filter(elem => elem.checked);

      if (activeState.length == 0) {
        $(selector).val('');
      } else if (activeState.length == 1) {
        $(selector).val(`${activeState[0].count} x ${activeState[0].text}`);
      } else {
        let sum = activeState.reduce(
          (acc, item) => acc + (item.checked ? item.count : 0),
          0,
        );
        $(selector).val(`${sum} Containers`);
      }

      $(selector).popover('hide');

      let containers = activeState.map(elem => `${elem.text}:${elem.count}`);

      $(`${selector}-hidden`).val(JSON.stringify(containers));
      $(".cargo-icon").css('display', containers.length ? 'none' : 'block');
      $(".cargo-icon-dark").css('display', containers.length ? 'block' : 'none');
    }

    popoverRender();
    if (data) popoverClickAction();

    $(selector).popover({
      trigger: 'manual',
      content: containerSelectPopover.get(0),
      placement: 'bottom',
      fallbackPlacement: ['bottom'],
      flip: 'bottom',
      html: true,
    }).on('click', function (e) {
      e.preventDefault();

      $(this).popover("show");

      $(".popover").css('margin-top', '20px')
      $(".popover").css('margin-left', '8px')
      $(".popover .arrow").css('display', "none")
    }).on('show.bs.popover', function() {
      $('html').on('mousedown', function (e) {
        var l = $(e.target);
        if ($('.container-popover-body').get(0) && l.get(0)) {
          let isInside = $.contains($('.container-popover-body').get(0), l.get(0));
          if (!isInside) {
            $(selector).popover('hide');
            popoverClickAction();
          }
        }
      });
    }).on('hide.bs.popover', function() {
      $('html').off('mousedown')
    });
  }

  const translation = initLocale($("body").data('locale'))

  createPopoverContainer({
    selector: '.cargo-select',
    containers: containers,
  });

  $('.cargo-arrow').on('click', function () {
    $('.cargo-select').trigger('click');
  })

  $('.cargo-icon').on('click', function () {
    $('.cargo-select').trigger('click');
  })
  // ============================================  
}