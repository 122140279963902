import { createAutoCompleteInput as compareComponent } from "./compare/autocomplete-input";
import { createAutoCompleteInput as trackComponent } from "./track/autocomplete-input";
import { createPopoverComponent } from "./compare/container-popover";
import { createDatePicker } from "./compare/datepicker";
import { createCompanySelector } from "./compare/company-selector";

$(()=>{
  const locale = $("body").data('locale')
  
  $.ajax({
    accepts: {
      mycustomtype: 'application/json'
    },
    dataType: 'json', // type of response data
    url: `/${locale}/selectors/`,
    success: function (data) {
      compareComponent(data);
      trackComponent(data);
      createPopoverComponent(data);
      createDatePicker();
      createCompanySelector();
    }
  })

  
  // =======================================================
  // Tabs Switching
  // =======================================================
  $('.component-search__tabs li').on('click', function () {
    $('.component-search__tabs li').removeClass('active');
    $('.component-search__tabs li a').removeClass('active');
    $(this).addClass('active');

    if($(this).attr("id") == "track-item") {
      $(".search-metric").css('display', 'none');
      $(".track-metric").css('display', 'flex');
    } else {
      $(".search-metric").css('display', 'flex');
      $(".track-metric").css('display', 'none');
    }
  });
  // =======================================================
});